import styles from './ContactBannerBlock.module.scss'
import { Col, Container, Row } from 'react-bootstrap'
import cmsConfigObject from '@/entities/cmsConfig/interface'
import cmsContactBannerBlockObject from '@/entities/cmsContactBannerBlock/interface'
import CloudinaryImage from '@/components/elements/media/CloudinaryImage'
import ThemeImage from '@/components/elements/media/ThemeImage/ThemeImage'

interface Props {
  block: cmsContactBannerBlockObject
  cmsConfig: cmsConfigObject
  className?: string
}

const ContactBannerBlock = ({ block, cmsConfig, className = '' }: Props) => {
  const { employee } = block
  let { slogan, title, text, phone, openingHours1, openingHours2, email } = cmsConfig.contactBlockSettings

  // Override with optional block settings
  phone = block.phone ? block.phone : phone
  email = block.email ? block.email : email

  const phoneLink = !phone ? phone : 'tel:' + phone.replace('(0)', '').split(' ').join('')

  return (
    <Container fluid className={`${styles.root} ${className}`} data-tr-view="cms_block:contact_banner:view">
      <Container>
        <Row className={styles.mainRow}>
          <div className={`${styles.imageWrap} img-ratio-1-1`}>
            <CloudinaryImage
              image={employee.image}
              altDefault={employee.firstName + ' ' + employee.lastName + ' | ' + employee.jobTitle}
              width={420}
              height={420}
              responsive={false}
            />
          </div>
          <Col xs={12} sm={{ span: 12, offset: 0 }} lg={{ span: 8, offset: 4 }} xl={{ span: 9, offset: 3 }} className={styles.title}>
            {slogan}
          </Col>
          <Col xs={12} sm={{ span: 9, offset: 3 }} lg={{ span: 8, offset: 4 }} xl={{ span: 9, offset: 3 }} className={styles.contentCol}>
            <Row>
              <Col lg={6} xl={7}>
                <div className={styles.subTitle}>{title}</div>
                <p className={styles.text}>{text}</p>
                <div className={styles.signature}>
                  <div className={styles.name}>
                    {employee.firstName} {employee.lastName}
                  </div>
                  <div className={styles.jobTitle}>{employee.jobTitle}</div>
                </div>
              </Col>
              <Col lg={6} xl={5}>
                <Row className={styles.contactBlock}>
                  <Col xs={'auto'} className={styles.icon}>
                    <ThemeImage src={'images/mandatory/icons/contact/phone.png'} width={60} height={60} alt="phone icon" />
                  </Col>
                  <Col className={'d-flex align-items-center'}>
                    <div>
                      <div className={styles.link}>
                        <a href={phoneLink} data-tr-click="cms_block:contact_banner_phone:click">
                          {phone}
                        </a>
                      </div>
                      <div className={styles.openingHours}>
                        {openingHours1}
                        {openingHours2 ? (
                          <>
                            <br />
                            {openingHours2}
                          </>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className={styles.contactBlock}>
                  <Col xs={'auto'} className={styles.icon}>
                    <ThemeImage src={'images/mandatory/icons/contact/mail.png'} width={65} height={65} alt="mail icon" />
                  </Col>
                  <Col className={'d-flex align-items-center'}>
                    <div className={styles.link}>
                      <a href={'mailto:' + email} data-tr-click="cms_block:contact_banner_mail:click">
                        {email}
                      </a>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export default ContactBannerBlock
