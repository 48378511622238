import TextWithIcon from '@/components/cms/TextWithIcon'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'next-i18next'
import travelDestinationObject from '@/entities/travelDestination/interface'
import ThemeImage from '@/components/elements/media/ThemeImage/ThemeImage'

interface Props {
  destination: travelDestinationObject
}

const DestinationInformation = ({ destination }: Props) => {
  const { t } = useTranslation('common')

  const groups = [
    {
      title: t('travel.countryInfo.entryRequirements'),
      text: destination.entryRequirements,
      icon: '/images/mandatory/icons/destination/entry-requirements.png',
    },
    {
      title: t('travel.countryInfo.timeShift'),
      text: destination.timeShift,
      icon: '/images/mandatory/icons/destination/time-shift.png',
    },
    {
      title: t('travel.countryInfo.medicalInformation'),
      text: destination.medicalInformation,
      icon: '/images/mandatory/icons/destination/medical.png',
    },
    {
      title: t('travel.countryInfo.climate'),
      text: destination.climate,
      icon: '/images/mandatory/icons/destination/climate.png',
    },
    {
      title: t('travel.countryInfo.language'),
      text: destination.language,
      icon: '/images/mandatory/icons/destination/language.png',
    },
    {
      title: t('travel.countryInfo.currency'),
      text: destination.currency,
      icon: '/images/mandatory/icons/destination/currency.png',
    },
  ]

  return (
    <Row data-tr-view="travel_page:destination_accordion_item_content:view">
      {groups.map((group, index) => {
        return (
          <Col md={6} key={index}>
            <TextWithIcon title={group.title} text={group.text} Icon={<ThemeImage src={group.icon} width={110} />} />
          </Col>
        )
      })}
    </Row>
  )
}

export default DestinationInformation
