import styles from './NewsletterForm.module.scss'
import { Trans, useTranslation } from 'next-i18next'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { useContext, useEffect, useState } from 'react'
import { TitleH2 } from '@/components/cms/Title'
import { Col, Row, Form, Button, Container, Alert } from 'react-bootstrap'
import graphQLClient from '@/lib/api/graphQLClient'
import ArrowIcon from '@/components/elements/icons/ArrowIcon'
import * as React from 'react'
import { PrivacyPolicyPageUrlContext } from '@/app/context'
import { sendNewsletterSignupEvent } from '@/lib/ga/gtag'
import { useTracking } from '@/integrations/tracking/useTracking'

// language=GraphQL
const REGISTER_NEWSLETTER_USER_MUTATION = `mutation RegisterNewsletterUser($input: RegisterNewsletterUserInput) {
  registerNewsletterUser(input: $input) {
    success
    message
  }
}`

const schema = yup.object().shape({
  salutation: yup.string().required().oneOf(['m', 'f'], 'Select salutation'),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().required(),
})
const initialValues = {
  salutation: '',
  firstName: '',
  lastName: '',
  email: '',
}

const NewsletterForm = () => {
  const { t } = useTranslation('common')
  const PrivacyPolicyPageUrl = useContext(PrivacyPolicyPageUrlContext)
  const { send } = useTracking()
  const [state, setState] = useState<'initial' | 'success' | 'error'>('initial')
  const [inputTracked, setInputTracked] = useState<boolean>(false)

  const formik = useFormik<{ [x: string]: any }>({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: () => {
      send.genericEvent({
        category: 'newsletter_signup',
        object: 'signup_form',
        action: 'submit',
        data: {},
      })
      graphQLClient
        .request(REGISTER_NEWSLETTER_USER_MUTATION, { input: { tenantId: process.env.NEXT_PUBLIC_TENANT_ID, ...formik.values } })
        .then((response) => {
          formik.setSubmitting(false)
          switch (response.registerNewsletterUser.success) {
            case true:
              sendNewsletterSignupEvent()
              send.genericEvent({
                category: 'newsletter_signup',
                object: 'signup_form',
                action: 'success',
                data: {},
              })
              setState('success')
              break
            case false:
              send.genericEvent({
                category: 'newsletter_signup',
                object: 'signup_form',
                action: 'error',
                data: {},
              })
              setState('error')
              break
          }
        })
    },
  })

  useEffect(() => {
    if (!inputTracked && formik.values.email) {
      setInputTracked(true)
      send.genericEvent({
        category: 'newsletter_signup',
        object: 'signup_form',
        action: 'input',
        data: {},
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.email])

  let content = <></>
  switch (state) {
    case 'success':
      content = (
        <>
          <Alert variant={'success'} className={'text-center mt-1 mb-3'}>
            {t('newsletter.registrationSuccess')}
          </Alert>
        </>
      )
      break

    case 'error':
      content = (
        <Alert variant={'danger'} className={'text-center mt-1 mb-3'}>
          {t('newsletter.registrationError')}
        </Alert>
      )
      break

    case 'initial':
      content = (
        <Form noValidate onSubmit={formik.handleSubmit} data-tr-view="newsletter_signup:signup_form:view">
          <Row>
            <Form.Group className="form-group" as={Col} controlId="newsletterFormEmail">
              <Form.Control
                type="text"
                name="email"
                placeholder={`${t('newsletter.email')}*`}
                value={formik.values.email}
                onChange={formik.handleChange}
                isInvalid={formik.touched.email && !!formik.errors.email}
                className={styles.input}
              />
              <Form.Control.Feedback type="invalid">* {t('form.validation.required')}</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="form-group" as={Col} sm="4" controlId="newsletterFormSalutation">
              <Form.Control
                as="select"
                className={styles.input + ' ' + styles.select + ' form-select'}
                name="salutation"
                placeholder={`${t('newsletter.salutation')}*`}
                value={formik.values.salutation}
                onChange={formik.handleChange}
                isInvalid={formik.touched.salutation && !!formik.errors.salutation}
              >
                <option value="" disabled>
                  {t('newsletter.salutation')} *
                </option>
                <option value="m">{t('newsletter.mr')}</option>
                <option value="f">{t('newsletter.mrs')}</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">* {t('form.validation.required')}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="form-group" as={Col} sm="4" controlId="newsletterFormFirstName">
              <Form.Control
                type="text"
                name="firstName"
                placeholder={`${t('newsletter.firstName')}*`}
                value={formik.values.firstName}
                onChange={formik.handleChange}
                isInvalid={formik.touched.firstName && !!formik.errors.firstName}
                className={styles.input}
              />
              <Form.Control.Feedback type="invalid">* {t('form.validation.required')}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="form-group" as={Col} sm="4" controlId="newsletterFormLastName">
              <Form.Control
                type="text"
                name="lastName"
                placeholder={`${t('newsletter.lastName')}*`}
                value={formik.values.lastName}
                onChange={formik.handleChange}
                isInvalid={formik.touched.lastName && !!formik.errors.lastName}
                className={styles.input}
              />
              <Form.Control.Feedback type="invalid">* {t('form.validation.required')}</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="justify-content-end justify-content-md-between">
            <Col xs="auto" className={`mb-3 mb-lg-0 ${styles.submitWrap}`}>
              <Row>
                <Col xs="auto">
                  <ArrowIcon fontsize={'9rem'} className={styles.arrow} />
                </Col>
                <Col xs="auto">
                  <Button type="submit" variant={'transparent'} className={styles.submitButton} disabled={formik.isSubmitting}>
                    {`${t('newsletter.register')}`}
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col md={8} className={`order-md-first d-none d-md-block mb-5 ${styles.text}`}>
              {t('newsletter.promoText')}{' '}
              <span className={styles.gtcInfo}>
                <Trans i18nKey={'newsletter.gtcInfo'}>
                  I accept the usage of cookies and the
                  <a href={PrivacyPolicyPageUrl} target={'_blank'} rel="noreferrer">
                    privacy policy
                  </a>
                  .
                </Trans>
              </span>
            </Col>
          </Row>
        </Form>
      )
      break
  }

  return (
    <div className={styles.root + ' an-wobble-horizontal-trigger'}>
      <Container>
        <Row>
          <Col xs={12}>
            <TitleH2 title={t('newsletter.newsletterRegistration')} className={styles.titleWrap} />
          </Col>
          <Col className={`my-3 d-md-none ${styles.text}`}>
            {t('newsletter.promoText')}{' '}
            <span className={styles.gtcInfo}>
              <Trans i18nKey={'newsletter.gtcInfo'}>
                I accept the usage of cookies and the
                <a href={PrivacyPolicyPageUrl} target={'_blank'} rel="noreferrer">
                  privacy policy
                </a>
                .
              </Trans>
            </span>
          </Col>
        </Row>
        {content}
      </Container>
    </div>
  )
}

export default NewsletterForm
